html, body {
    overflow: hidden;
    font-size: 16px;
}
body {
    width: 480px;
    margin: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1rem;
    justify-content: center;

}

.PrereleaseHome__outer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 8px;
    height: 160px;
    margin-bottom: -12px;
    margin-left: -4px;
}

.PrereleaseHome__inner {
    font-family: 'rockwell_stdextra_bold';
    font-size: 8rem;
    letter-spacing: -.5rem;
    display: inline-block;
}

.PrereleaseHome__form {
    border: 1px solid black;
    position: relative;
}

.PrereleaseHome__form-message {
    display: flex; 
    position: absolute; 
    width: 100%; 
    height: 100%; 
    top: 0; 
    padding: 20px;
    background: rgba(255, 255, 255, .95);
    align-items: center;

}

.PrereleaseHome__legal {
    margin-top: 6rem;
    font-size: small;
    text-align: center;
}
